import { render, staticRenderFns } from "./plCrcParticulars.vue?vue&type=template&id=d7c658fe&scoped=true&"
import script from "./plCrcParticulars.js?vue&type=script&lang=js&"
export * from "./plCrcParticulars.js?vue&type=script&lang=js&"
import style0 from "./plCrcParticulars.less?vue&type=style&index=0&id=d7c658fe&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d7c658fe",
  null
  
)

export default component.exports